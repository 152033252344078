<template>
    <div class="pa-3">
        <div class="pa-3 white rounded">
            <v-text-field v-model="barcode" placeholder="Barcode" flat hide-details solo-inverted 
                v-mask="'############'" @keydown.enter="loadItem" ref="barcode" @focus="focusField = 'barcode'" @blur="onBlur"></v-text-field>
        </div>

        <v-card flat class="mt-3" :loading="loadingView">
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <div class="subtitle-1 mb-3">Last scanned item</div>

                        <v-alert dense text border="left" v-if="item != null && item.message" type="warning">{{ item.message }}</v-alert>

                        <div class="headline grey--text mt-5" v-show="item == null">Scan a barcode to begin</div>

                        <v-simple-table v-show="item != null">
                            <tbody>
                                <tr>
                                    <th>barcode</th>
                                    <td>{{ item == null ? '' : item.barcode }}</td>
                                </tr>
                                <tr>
                                    <th>Serial</th>
                                    <td>{{ item == null ? '' : item.id }}</td>
                                </tr>
                                <tr>
                                    <th>Current status</th>
                                    <td>{{ item == null ? '' : item.status }}</td>
                                </tr>
                                <tr>
                                    <th>Product</th>
                                    <td>{{ item == null ? '' : `[${item.product.style}] ${item.product.description}` }}</td>
                                </tr>
                                <tr v-show="item != null && ( item.product.categoryID == 'C' || item.product.categoryID == 'P' )">
                                    <th>Current altered</th>
                                    <td>{{ item == null ? '' : item.altered }}</td>
                                </tr>
                                <tr v-show="item != null && ( item.product.categoryID == 'C' || item.product.categoryID == 'P' )">
                                    <th>New altered</th>
                                    <td>
                                        <v-row>
                                            <v-col cols="9">
                                                <v-text-field v-model="altered" placeholder="New value" flat hide-details solo-inverted dense
                                                    ref="altered" @keypress.enter.prevent="updateAlteration" 
                                                    @focus="onAlteredFocus" @blur="onBlur" :rules="[rules.required, rules.isNumeric]"></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-btn depressed block color="grey darken-4" class="white--text" :loading="altering" 
                                                    @click="updateAlteration">Save</v-btn>
                                            </v-col>
                                        </v-row>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>

                    <v-col cols="6" v-if="item != null">
                        <v-img :src="item.product.imgSrc" max-width="400" style="margin: 0 auto;" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { _st } from '@/softech';
import { mask } from 'vue-the-mask';

export default {
    data: () => ({
        loadingView     : false,
        barcode         : '',
        altered         : '',
        altering        : false,

        focusField      : '',
        rules           : {
            required        : v => !!v || 'required',
            isNumeric       : v => _st.isNumeric( v ) || 'must be numeric',
        },
    }),
    computed: {
        ...mapGetters({
            item    : 'stations/inStorage/item'
        })
    },
    mounted() {
        this.$nextTick(() => {
            if( this.item == null ) 
                this.$refs.barcode.focus();
            else if( this.item.product.categoryID == 'C' || this.item.product.categoryID == 'P' )
                this.$refs.altered.focus();
        });
    },
    methods: {
        ...mapActions({
           scanItem         : 'stations/inStorage/scanItem',
           setAlteration    : 'stations/inStorage/setAlteration',
           printLabel       : 'stations/inStorage/printLabel'
        }),
        async loadItem() {
            try {
                this.playBeep();

                this.loadingView = true;
                await this.scanItem( this.barcode );
                this.loadingView = false;
                this.barcode = '';

                if( this.item.product.categoryID == 'C' || this.item.product.categoryID == 'P' ) {
                    this.altered = this.item.altered;
                    this.$refs.altered.focus();
                }
            }
            catch(error) {
                this.loadingView = false;
                this.barcode = '';
            }
        },
        async updateAlteration() {
            try {
                this.playBeep();
                
                this.altering = true;
                await this.setAlteration( this.altered );
                await this.printLabel();
                this.altering = false;
                this.altered = '';

                this.mxShowMessage('New alteration set', 'success');
                this.focusField = 'barcode';
                this.onBlur();
            }
            catch(error) {
                this.altering = false;
            }
        },
        onAlteredFocus() {
            this.focusField = 'altered';
            let input = this.$refs.altered.$el.querySelector('input');
            setTimeout(() => input.select(), 100);
        },
        onBlur() {
            setTimeout(() => {
                if( this.item == null || this.focusField == 'barcode' ) 
                    this.$refs.barcode.focus();
                else if( this.item != null && this.item.product.categoryID == 'C' || this.item.product.categoryID == 'P' )
                    this.$refs.altered.focus();
            }, 100);
        }
    },
    directives: { mask }
}
</script>

<style lang="scss" scoped>

</style>